export const modulePermission = () => {
    const permission = localStorage.getItem('permission');
    return permission;
};

export function translateFormatter(text) {
    // console.log(text)
    let res = text.replace(/!|\$|%/g, '')
        .replace('succesfully', 'successfully')
        .replace('Vecancy', 'Vacancy')
        .replace('Calendar', 'calender')
        .trim()
        .replace(/\s/g, '_')
        .replace('.', '')
        .toUpperCase()
    // console.log(res)
    return res
}