import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        news_list : [],
        gallery_details :[],
        uploadLoader : false,
        editedNewsImage : [],
        newsDelId : [],
        editedAnnouncementImage : [],
        announcementDelId : [],
        news_comments : [],
        userLoader : false,
        galleryDelId : [],
        eventDelId : [],
        submitLoader : false,
        calenderDelId : [],
        unit_details: [],
        createdByUserList : [],
        userList : [],
        activity_loader : false,
        alert_loader : false,
        announcement_loader : false,
        web_dashboard_loader : false,
        training_loader : false,
        suggestion_loader : false,
        role_loader : false,
        quick_loader : false,
        poll_loader : false,
        news_loader : false,
        vacancy_loader : false,
        initiative_loader : false,
        happiness_loader : false,
        gallery_loader : false,
        event_loader : false,
        evaluation_loader : false,
        discussion_loader : false,
        calendar_loader : false,
        benefit_loader : false,
        activity_view_loader : false,
        training_applied_loader : false,
        training_views_loader : false,
        quick_view_loader : false,
        news_view_loader : false,
        poll_answer_loader : false,
        poll_detail_loader : false,
        poll_view_loader : false,
        news_comment_loader : false,
        news_like_loader : false,
        vacancy_applied_loader : false,
        vacancy_view_loader : false,
        discussion_view_loader : false,
        event_view_loader : false,
        event_register_loader : false,
        initiative_view_loader : false,
        alert_view_loader : false,
        announcement_view_loader : false,
        view_benefit_loader : false,
        activity_applied_loader : false,
        // discussion_loader : false,
        // calendar_loader : false,
        // discussion_loader : false,
        // calendar_loader : false,
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})

export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const news_list = (state ) => state.commonReducer.news_list;
export const newsPath = (state ) => state.commonReducer.newsPath;
export const announcement_list = (state ) => state.commonReducer.announcement_list;
export const announcementPath = (state ) => state.commonReducer.announcementPath;
export const polls_list = (state ) => state.commonReducer.polls_list;
export const event_list = (state ) => state.commonReducer.event_list;
export const eventPath = (state ) => state.commonReducer.eventPath;
export const access_list = (state ) => state.commonReducer.access_list;
export const accessPath = (state ) => state.commonReducer.accessPath;
export const faq_details = (state ) => state.commonReducer.faq_details;
export const gallery_list = (state ) => state.commonReducer.gallery_list;
export const galleryPath = (state ) => state.commonReducer.galleryPath;
export const departmentDetails = (state ) => state.commonReducer.departmentDetails;
export const gallery_details = (state ) => state.commonReducer.gallery_details;
export const imagePath = (state ) => state.commonReducer.imagePath;
export const uploadLoader = (state ) => state.commonReducer.uploadLoader;
export const editedNewsImage = (state ) => state.commonReducer.editedNewsImage;
export const newsDelId = (state ) => state.commonReducer.newsDelId;
export const editedAnnouncementImage = (state ) => state.commonReducer.editedAnnouncementImage;
export const announcementDelId = (state ) => state.commonReducer.announcementDelId;
export const news_comments = (state ) => state.commonReducer.news_comments;
export const editedEventImage = (state ) => state.commonReducer.editedEventImage;
export const eventDelId = (state ) => state.commonReducer.eventDelId;
export const unit_details = (state ) => state.commonReducer.unit_details;
export const subunitlists = (state ) => state.commonReducer.subunitlists;
export const workshift_details = (state ) => state.commonReducer.workshift_details;
export const jobLists = (state ) => state.commonReducer.jobLists;
export const rankLists = (state ) => state.commonReducer.rankLists;
export const userLoader = (state ) => state.commonReducer.userLoader;
export const user_details = (state ) => state.commonReducer.user_details;
export const totalPageCount = (state ) => state.commonReducer.totalPageCount;
export const currentPage = (state ) => state.commonReducer.currentPage;
export const editUserDetails = (state ) => state.commonReducer.editUserDetails;
export const multiSubunitLists = (state ) => state.commonReducer.multiSubunitLists;
export const multiWorkshiftLists = (state ) => state.commonReducer.multiWorkshiftLists;
export const userList = (state ) => state.commonReducer.userList;
export const permissionModules = (state ) => state.commonReducer.permissionModules;
export const editNewsDetails = (state ) => state.commonReducer.editNewsDetails;
export const roleUsers = (state ) => state.commonReducer.roleUsers;
export const modules = (state ) => state.commonReducer.modules;
export const userRoles = (state ) => state.commonReducer.userRoles;
export const rolesTotalCount = (state ) => state.commonReducer.rolesTotalCount;
export const editAnnouncementDetails = (state ) => state.commonReducer.editAnnouncementDetails;
export const announcement_comments = (state ) => state.commonReducer.announcement_comments;
export const editEventDetails = (state ) => state.commonReducer.editEventDetails;
export const suggestionsList = (state ) => state.commonReducer.suggestionsList;
export const suggestionsPath = (state ) => state.commonReducer.suggestionsPath;
export const suggestionTotalPageCount = (state ) => state.commonReducer.suggestionTotalPageCount;
export const discussionList = (state ) => state.commonReducer.discussionList;
export const discussionPath = (state ) => state.commonReducer.discussionPath;
export const discussionTotalPageCount = (state ) => state.commonReducer.discussionTotalPageCount;
export const discussionData = (state ) => state.commonReducer.discussionData;
export const discussionDetailPath = (state ) => state.commonReducer.discussionDetailPath;
export const eventList = (state ) => state.commonReducer.eventList;
export const calenderEvent = (state ) => state.commonReducer.calenderEvent;
export const polls_details = (state ) => state.commonReducer.polls_details;
export const training_list = (state ) => state.commonReducer.training_list;
export const trainingPath = (state ) => state.commonReducer.trainingPath;
export const editTrainingDetails = (state ) => state.commonReducer.editTrainingDetails;
export const rank_details = (state ) => state.commonReducer.rank_details;
export const editRoleDetails = (state ) => state.commonReducer.editRoleDetails;
export const alert = (state ) => state.commonReducer.alert;
export const editAlertDetails = (state ) => state.commonReducer.editAlertDetails;
export const alertPath = (state ) => state.commonReducer.alertPath;
export const editedAccessImage = (state ) => state.commonReducer.editedAccessImage;
export const editAccessDetails = (state ) => state.commonReducer.editAccessDetails;
export const vaccancy_list = (state ) => state.commonReducer.vaccancy_list;
export const vaccancy_details = (state ) => state.commonReducer.vaccancy_details;
export const benefit_details = (state ) => state.commonReducer.benefit_details;
export const benefits = (state ) => state.commonReducer.benefits;
export const activities = (state ) => state.commonReducer.activities;
export const activityDetails = (state ) => state.commonReducer.activityDetails;
export const initiatives = (state ) => state.commonReducer.initiatives;
export const initiatives_details = (state ) => state.commonReducer.initiatives_details;
export const faqDetailData = (state ) => state.commonReducer.faqDetailData;
export const webLogoPath = (state ) => state.commonReducer.webLogoPath;
export const web_dashboard_data = (state ) => state.commonReducer.web_dashboard_data;
export const web_dashboard_details = (state ) => state.commonReducer.web_dashboard_details;
export const galleryDetails = (state ) => state.commonReducer.galleryDetails;
export const editedGalleryImage = (state ) => state.commonReducer.editedGalleryImage;
export const galleryDelId = (state ) => state.commonReducer.galleryDelId;
export const evaluation_list = (state ) => state.commonReducer.evaluation_list;
export const evaluation_details = (state ) => state.commonReducer.evaluation_details;
export const initiativeUsersList = (state ) => state.commonReducer.initiativeUsersList;
export const newsTotalPages = (state ) => state.commonReducer.newsTotalPages;
export const announcementTotalPages = (state ) => state.commonReducer.announcementTotalPages;
export const eventTotalPages = (state ) => state.commonReducer.eventTotalPages;
export const accessTotalPages = (state ) => state.commonReducer.accessTotalPages;
export const trainingTotalPages = (state ) => state.commonReducer.trainingTotalPages;
export const evaluationTotalPages = (state ) => state.commonReducer.evaluationTotalPages;
export const vacancyTotalPages = (state ) => state.commonReducer.vacancyTotalPages;
export const benefitTotalPages = (state ) => state.commonReducer.benefitTotalPages;
export const activityTotalPages = (state ) => state.commonReducer.activityTotalPages;
export const pollTotalPages = (state ) => state.commonReducer.pollTotalPages;
export const initiativeTotalPages = (state ) => state.commonReducer.initiativeTotalPages;
export const alertTotalPages = (state ) => state.commonReducer.alertTotalPages;
export const webTotalPages = (state ) => state.commonReducer.webTotalPages;
export const faqTotalPages = (state ) => state.commonReducer.faqTotalPages;
export const galleryTotalPages = (state ) => state.commonReducer.galleryTotalPages;
export const news_comments_pages = (state ) => state.commonReducer.news_comments_pages;
export const submitLoader = (state ) => state.commonReducer.submitLoader;
export const about = (state ) => state.commonReducer.about;
export const aboutTotalPages = (state ) => state.commonReducer.aboutTotalPages;
export const about_details = (state ) => state.commonReducer.about_details;
export const rules = (state ) => state.commonReducer.rules;
export const rulesTotalPages = (state ) => state.commonReducer.rulesTotalPages;
export const rule_details = (state ) => state.commonReducer.rule_details;
export const happiness = (state ) => state.commonReducer.happiness;
export const happinessTotalPageCount = (state ) => state.commonReducer.happinessTotalPageCount;
export const happinessDetails = (state ) => state.commonReducer.happinessDetails;
export const departmentUserList = (state ) => state.commonReducer.departmentUserList;
export const createdByUser = (state ) => state.commonReducer.createdByUser;
export const initiativePath = (state ) => state.commonReducer.initiativePath;
export const translate_details = (state ) => state.commonReducer.translate_details;
export const leaderboard_details = (state ) => state.commonReducer.leaderboard_details;
export const current_user_leaderboard_details = (state ) => state.commonReducer.current_user_leaderboard_details;
export const leaderboardTotalpages = (state ) => state.commonReducer.leaderboardTotalpages;
export const calenderEventTotalPages = (state ) => state.commonReducer.calenderEventTotalPages;
export const calenderEventList = (state ) => state.commonReducer.calenderEventList;
export const ad_count = (state ) => state.commonReducer.ad_count;
export const manual_count = (state ) => state.commonReducer.manual_count;
export const csv_count = (state ) => state.commonReducer.csv_count;
export const news_page_status = (state ) => state.commonReducer.news_page_status;
export const announcement_page_status = (state ) => state.commonReducer.announcement_page_status;
export const poll_page_status = (state ) => state.commonReducer.poll_page_status;
export const event_page_status = (state ) => state.commonReducer.event_page_status;
export const activity_page_status = (state ) => state.commonReducer.activity_page_status;
export const web_page_status = (state ) => state.commonReducer.web_page_status;
export const training_page_status = (state ) => state.commonReducer.training_page_status;
export const suggestions_page_status = (state ) => state.commonReducer.suggestions_page_status;
export const quick_page_status = (state ) => state.commonReducer.quick_page_status;
export const vacancy_page_status = (state ) => state.commonReducer.vacancy_page_status;
export const initiative_page_status = (state ) => state.commonReducer.initiative_page_status;
export const gallery_page_status = (state ) => state.commonReducer.gallery_page_status;
export const discussion_page_status = (state ) => state.commonReducer.discussion_page_status;
export const benefit_page_status = (state ) => state.commonReducer.benefit_page_status;
export const alert_page_status = (state ) => state.commonReducer.alert_page_status;
export const role_page_status = (state ) => state.commonReducer.role_page_status;
export const user_page_status = (state ) => state.commonReducer.user_page_status;
export const calender_page_status = (state ) => state.commonReducer.calender_page_status;
export const calenderTotalEvent = (state ) => state.commonReducer.calenderTotalEvent;
export const calenderDelId = (state ) => state.commonReducer.calenderDelId;
export const dashboard_state = (state) => state.commonReducer.dashboard;
export const analytics_Linechart_Data = (state) => state.commonReducer.analyticsLinechartData;
export const happiness_page_status = (state) => state.commonReducer.happiness_page_status;
export const polls_answer_details = (state) => state.commonReducer.polls_answer_details;
export const createdByUserList = (state) => state.commonReducer.createdByUserList;
export const allUserList = (state) => state.commonReducer.allUserList;
export const polls_answer_total_pages = (state) => state.commonReducer.polls_answer_total_pages;
export const benefitPath = (state) => state.commonReducer.benefitPath;

export const activity_loader = (state) => state.commonReducer.activity_loader;
export const alert_loader = (state) => state.commonReducer.alert_loader;
export const announcement_loader = (state) => state.commonReducer.announcement_loader;
export const web_dashboard_loader = (state) => state.commonReducer.web_dashboard_loader;
export const training_loader = (state) => state.commonReducer.training_loader;
export const suggestion_loader = (state) => state.commonReducer.suggestion_loader;
export const role_loader = (state) => state.commonReducer.role_loader;
export const quick_loader = (state) => state.commonReducer.quick_loader;
export const poll_loader = (state) => state.commonReducer.poll_loader;
export const news_loader = (state) => state.commonReducer.news_loader;
export const vacancy_loader = (state) => state.commonReducer.vacancy_loader;
export const initiative_loader = (state) => state.commonReducer.initiative_loader;
export const happiness_loader = (state) => state.commonReducer.happiness_loader;
export const gallery_loader = (state) => state.commonReducer.gallery_loader;
export const event_loader = (state) => state.commonReducer.event_loader;
export const evaluation_loader = (state) => state.commonReducer.evaluation_loader;
export const discussion_loader = (state) => state.commonReducer.discussion_loader;
export const calendar_loader = (state) => state.commonReducer.calendar_loader;
export const benefit_loader = (state) => state.commonReducer.benefit_loader;

export const activity_view_loader = (state) => state.commonReducer.activity_view_loader;
export const training_applied_loader = (state) => state.commonReducer.training_applied_loader;
export const training_views_loader = (state) => state.commonReducer.training_views_loader;
export const quick_view_loader = (state) => state.commonReducer.quick_view_loader;
export const news_view_loader = (state) => state.commonReducer.news_view_loader;
export const poll_answer_loader = (state) => state.commonReducer.poll_answer_loader;
export const poll_detail_loader = (state) => state.commonReducer.poll_detail_loader;
export const poll_view_loader = (state) => state.commonReducer.poll_view_loader;
export const news_comment_loader = (state) => state.commonReducer.news_comment_loader;
export const news_like_loader = (state) => state.commonReducer.news_like_loader;
export const vacancy_applied_loader = (state) => state.commonReducer.vacancy_applied_loader;
export const vacancy_view_loader = (state) => state.commonReducer.vacancy_view_loader;
export const discussion_view_loader = (state) => state.commonReducer.discussion_view_loader;
export const event_view_loader = (state) => state.commonReducer.event_view_loader;
export const event_register_loader = (state) => state.commonReducer.event_register_loader;
export const initiative_view_loader = (state) => state.commonReducer.initiative_view_loader;
export const alert_view_loader = (state) => state.commonReducer.alert_view_loader;
export const announcement_view_loader = (state) => state.commonReducer.announcement_view_loader;
export const view_benefit_loader = (state) => state.commonReducer.view_benefit_loader;
export const activity_applied_loader = (state) => state.commonReducer.activity_applied_loader;
export const happinessCountList = (state) => state.commonReducer.happinessCountList;
export const rulesPath = (state) => state.commonReducer.rulesPath;
export const ruleDetails = (state) => state.commonReducer.ruleDetails;
export const rulesPageStatus = (state) => state.commonReducer.rulesPageStatus;
export const leaderboardUsers = (state) => state.commonReducer.leaderboardUsers;
export const leaderboardDetails = (state) => state.commonReducer.leaderboardDetails;
export const leaderboard_modules = (state) => state.commonReducer.leaderboard_modules;
export const leaderboard_loader = (state) => state.commonReducer.leaderboard_loader;
export const alert_module_data = (state) => state.commonReducer.alert_module_data;
export const dashboard_happiness = (state) => state.commonReducer.dashboard_happiness;

export default commonSlice.reducer;