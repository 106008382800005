import {
  Main,
  Gallery,
  NewsLetter,
  Announcement,
  Calender,
  Discussion,
  Events,
  Faq,
  Polls,
  QuickAccess,
  Role,
  Suggestions,
  Unit,
  User,
  Training,
  Rank,
  InternalVacancy,
  Benefits,
  Activities,
  Initiatives,
  Web,
  Alert,
  About,
  Rules,
  Happiness,
  MenuText,
  Leaderboard,
} from "./pageListAsync";

export const routeConfig = [
  {
    id: 0,
    path: "/",
    permissionKey: "",
    component: Main,
  },
  {
    id: 1,
    path: "/dashboard",
    permissionKey: "",
    component: Main,
  },
  {
    id: 2,
    path: "/news-:tab",
    permissionKey: "news",
    component: NewsLetter,
  },
  {
    id: 3,
    path: "/news-:tab/:id",
    permissionKey: "news",
    component: NewsLetter,
  },
  {
    id: 4,
    path: "/announcement-:tab",
    permissionKey: "announcements",
    component: Announcement,
  },
  {
    id: 5,
    path: "/announcement-:tab/:id",
    permissionKey: "announcements",
    component: Announcement,
  },
  {
    id: 6,
    path: "/survey-:tab",
    permissionKey: "survey",
    component: Polls,
  },
  {
    id: 7,
    path: "/survey-:tab/:id",
    permissionKey: "survey",
    component: Polls,
  },
  {
    id: 8,
    path: "/events-:tab",
    permissionKey: "events",
    component: Events,
  },
  {
    id: 9,
    path: "/events-:tab/:id",
    permissionKey: "events",
    component: Events,
  },
  {
    id: 10,
    path: "/quick-access-:tab",
    permissionKey: "quick_access",
    component: QuickAccess,
  },
  {
    id: 9,
    path: "/quick-access-:tab/:id",
    permissionKey: "quick_access",
    component: QuickAccess,
  },
  {
    id: 11,
    path: "/faq-:tab",
    permissionKey: "faq",
    component: Faq,
  },
  {
    id: 11,
    path: "/faq-:tab/:id",
    permissionKey: "faq",
    component: Faq,
  },
  {
    id: 12,
    path: "/gallery-:tab",
    permissionKey: "gallery",
    component: Gallery,
  },
  {
    id: 12,
    path: "/gallery-:tab/:id",
    permissionKey: "gallery",
    component: Gallery,
  },
  {
    id: 13,
    path: "/user-:tab",
    permissionKey: "user",
    component: User,
  },
  {
    id: 14,
    path: "/user-:tab/:id",
    permissionKey: "user",
    component: User,
  },
  {
    id: 15,
    path: "/unit",
    permissionKey: "general",
    component: Unit,
  },
  {
    id: 25,
    path: "/rank",
    permissionKey: "general",
    component: Rank,
  },
  {
    id: 16,
    path: "/role-:tab",
    permissionKey: "roles",
    component: Role,
  },
  {
    id: 17,
    path: "/role-:tab/:id",
    permissionKey: "roles",
    component: Role,
  },
  {
    id: 18,
    path: "/suggestions",
    permissionKey: "suggestions",
    component: Suggestions,
  },
  {
    id: 19,
    path: "/discussions",
    permissionKey: "discussions",
    component: Discussion,
  },
  {
    id: 20,
    path: "/discussions-:tab/:id",
    permissionKey: "discussions",
    component: Discussion,
  },
  {
    id: 21,
    path: "/calender-:tab",
    permissionKey: "calendar",
    component: Calender,
  },
  {
    id: 22,
    path: "/calender-:tab/:id",
    permissionKey: "calendar",
    component: Calender,
  },
  {
    id: 23,
    path: "/training-:tab",
    permissionKey: "training",
    component: Training,
  },
  {
    id: 24,
    path: "/training-:tab/:id",
    permissionKey: "training",
    component: Training,
  },
  {
    id: 26,
    path: "/alert-:tab",
    permissionKey: "alert",
    component: Alert,
  },
  {
    id: 27,
    path: "/alert-:tab/:id",
    permissionKey: "alert",
    component: Alert,
  },
  {
    id: 28,
    path: "/internal-vacancy-:tab",
    permissionKey: "internal_vacancies",
    component: InternalVacancy,
  },
  {
    id: 29,
    path: "/internal-vacancy-:tab/:id",
    permissionKey: "internal_vacancies",
    component: InternalVacancy,
  },
  {
    id: 30,
    path: "/benefits-:tab",
    permissionKey: "benefits",
    component: Benefits,
  },
  {
    id: 31,
    path: "/benefits-:tab/:id",
    permissionKey: "benefits",
    component: Benefits,
  },
  {
    id: 32,
    path: "/activities-:tab",
    permissionKey: "activities",
    component: Activities,
  },
  {
    id: 33,
    path: "/activities-:tab/:id",
    permissionKey: "activities",
    component: Activities,
  },
  {
    id: 34,
    path: "/initiatives-:tab",
    permissionKey: "initiatives",
    component: Initiatives,
  },
  {
    id: 35,
    path: "/initiatives-:tab/:id",
    permissionKey: "initiatives",
    component: Initiatives,
  },
  {
    id: 36,
    path: "/web-dashboard-:tab",
    permissionKey: "dashboard_item",
    component: Web,
  },
  {
    id: 37,
    path: "/web-dashboard-:tab/:id",
    permissionKey: "dashboard_item",
    component: Web,
  },
  {
    id: 40,
    path: "/about",
    permissionKey: "cms",
    component: About,
  },
  {
    id: 41,
    path: "/rules-:tab",
    permissionKey: "cms",
    component: Rules,
  },
  {
    id: 41,
    path: "/rules-:tab/:id",
    permissionKey: "cms",
    component: Rules,
  },
  {
    id: 42,
    path: "/happiness",
    permissionKey: "happiness_meter",
    component: Happiness,
  },
  {
    id: 43,
    path: "/happiness-:tab/:id",
    permissionKey: "happiness_meter",
    component: Happiness,
  },
  {
    id: 44,
    path: "/menu-text",
    permissionKey: "web_menu_text",
    component: MenuText,
  },
  {
    id: 44,
    path: "/leaderboard-:tab",
    permissionKey: "leaderboard",
    component: Leaderboard,
  },
  {
    id: 45,
    path: "/leaderboard-:tab/:id",
    permissionKey: "leaderboard",
    component: Leaderboard,
  },
];
