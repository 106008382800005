import { lazy } from "react";


export const Main = lazy(() => import("../pages/main"));
export const Gallery = lazy(() => import("../pages/gallery"));
export const NewsLetter = lazy(() => import("../pages/newsLetter"));
export const NotFound = lazy(() => import("../pages/notFound"));
export const Announcement = lazy(() => import("../pages/announcement"));
export const Calender = lazy(() => import("../pages/calender"));
export const Discussion = lazy(() => import("../pages/discussion"));
export const Events = lazy(() => import("../pages/events"));
export const Faq = lazy(() => import("../pages/faq"));
export const Polls = lazy(() => import("../pages/polls"));
export const QuickAccess = lazy(() => import("../pages/quickAccess"));
export const Role = lazy(() => import("../pages/roles"));
export const Suggestions = lazy(() => import("../pages/suggestions"));
export const Unit = lazy(() => import("../pages/unit"));
export const User = lazy(() => import("../pages/user"));
export const Training = lazy(() => import("../pages/Training"));
export const Rank = lazy(() => import("../pages/rank"));
export const InternalVacancy = lazy(() => import("../pages/internalVacancy"));
export const Benefits = lazy(() => import("../pages/benefits"));
export const Activities = lazy(() => import("../pages/activities"));
export const Initiatives = lazy(() => import("../pages/initiatives"));
export const Web = lazy(() => import("../pages/web"));
export const Alert = lazy(() => import("../pages/alert"));
export const About = lazy(() => import("../pages/About"));
export const Rules = lazy(() => import("../pages/Rules"));
export const Happiness = lazy(() => import("../pages/happiness"));
export const MenuText = lazy(() => import("../pages/menuText"));
export const Leaderboard = lazy(() => import("../pages/leaderboard"));
export const Login = lazy(() => import("../components/login/Login"));
export const PdfViewer = lazy(() => import("../components/common/pdfView"));
export const ExcelImport = lazy(() => import("../pages/excelImport"));

