import React from 'react';
import logo from './logo.svg';
import RouteHandler from './router/routes';
import './styles.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <RouteHandler />
    </div>
  );
}

export default App;
