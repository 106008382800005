import React, { Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { createMemoryHistory } from "history";
import { routeConfig } from "./routeConfig";
import { modulePermission } from "../utils/helpers";
import { useSelector } from "react-redux";
import { permissionModules } from "../redux/commonReducer";
import PrivateRoutes from "../utils/PrivateRoutes";
import { Login,NotFound,PdfViewer,ExcelImport } from "./pageListAsync";

const RouteHandler = () => {
  const history = createMemoryHistory();
  const permission = useSelector(permissionModules);

  return (
    <>
      <Suspense
        fallback={
          <div className="loader-div fullLoaderRoute">
            <img
              className="circles-loader"
              src="/assets/loader/loader.svg"
              alt=""
            />
          </div>
        }
      >
        <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              {routeConfig.map((item) => {
                if (
                  item.permissionKey === "" ||
                  modulePermission()?.includes(item.permissionKey)
                ) {
                  return (
                    <Route
                      key={item.id}
                      path={item.path}
                      element={<item.component />}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/pdf/:path" element={<PdfViewer />} />
            <Route path="/excelImport" element={<ExcelImport />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
};

export default RouteHandler;
